<template lang="pug">
.channel-settings-modal-wrapper
  .channel-settings-modal
    template(v-if="dialogIsLoading")
      .loader
          b-spinner(
            variant="success"
            style="width: 5rem; height: 5rem;"
          )
    template(v-else-if="currentInstance")
      .channel-name
        h5 {{ $t('ewa.modals.channelName') }}
        h2.ewa {{ formattedNumber }}
        Status(:active="dateExpired")
      Divider
      .messages
        h5 {{ $t('ewa.modals.messages') }}
        button.current-status(id="settings-popover") {{ messageStatus[currentSetting] }}
        b-popover(
          triggers="click blur"
          target="settings-popover"
          custom-class="custom-popover settings-popover"
        )
          .status-options()
            .option(v-for="value, index in messageStatus" @click="setCurrentSetting(index)" :class="{ active: currentSetting == index }")
              .checkbox
                .inner(v-if="currentSetting == index")
              p {{ $t(`ewa.modals.messageStatus.${index}`) }}
      Divider
      .subscription
        h5 {{ $t('ewa.modals.subscription') }}
        .date {{ formattedDate }}
      Divider
      .crm-settings
        .with-help
          h5  {{ $t('ewa.modals.crmSettings') }}
          .help(id="help-popover")
            p ?
          b-popover(
            triggers="hover focus"
            target="help-popover"
            placement="righttop"
            custom-class="custom-popover help-popover"
          )
            .help-text-settings 
              p Зайдите в Битрикс24 разделе "Контакт-центр", выберите приложение "EWA". Если у вас уже подключена открытая линия WhatsApp, выберите ее и нажмите "изменить", если не подключена, выберите поле "Создать открытую линию".
              p(style="margin-top: 14px;") В появившемся окне введите ID и ключ API в соответствующие поля и нажмите "сохранить"
        Input(name="ID Instance" v-if="currentInstance.idInstance" :value="currentInstance.idInstance.toString()" :copy="currentInstance.idInstance.toString()" disabled)
        Input(name="API token" v-if="currentInstance.apiKey" :value="currentInstance.apiKey" :copy="currentInstance.apiKey" disabled)
  //.spacer
  .footer
    EButton(:action="$root.closeDialog")
      p Отмена
    EButton.succeed(:action="saveSettings")
      p Сохранить
</template>

<script>
import Input from "@/components/ewa/common/Input.vue";
import Status from "@/components/ewa/common/Status.vue";
import Divider from "@/components/ewa/common/Divider.vue";

import moment from "moment";
import { StoreActionTypes } from "@/store/types";

export default {
  name: "Modal.ChannelSettings",
  props: {
    item: {
      required: true,
    },
  },
  components: {
    Input,
    Status,
    Divider,
  },
  async mounted() {
    await this.$store.dispatch(
      StoreActionTypes.GET_INSTANCE_SETTINGS,
      this.item.idInstance
    );
    this.currentSetting =
      this.currentInstance.settings.readChatSettings.eventOnIncomingMessage;
  },
  computed: {
    dialogIsLoading() {
      return this.$store.getters.ewaDialogIsLoading;
    },
    currentInstance() {
      return this.$store.getters.getCurrentInstance;
    },
    dateExpired() {
      return moment() > moment(this.currentInstance.created);
    },
    formattedDate() {
      return moment(this.currentInstance.created).format("DD.MM.YYYY HH:MM");
    },
    formattedNumber() {
      return this.currentInstance.wid
        ? `+${this.currentInstance.wid.split("@")[0]}`
        : "[Номер не привязан]";
    },
  },
  data() {
    return {
      messageStatus: {
        setReadedOnReceive: "Помечать прочитанным при получении",
        notSetReaded: "Оставлять непрочитанным",
        setReadedOnSend: "Отмечать прочитанным при отправке",
      },
      showStatusOptions: false,
      currentSetting: null,
    };
  },
  methods: {
    async saveSettings() {
      await this.$store.dispatch(StoreActionTypes.SET_INSTANCE_SETTINGS, {
        readChatSettings: {
          eventOnIncomingMessage: this.currentSetting,
        },
      });
      this.$root.closeDialog();
      await this.$store.dispatch(StoreActionTypes.GET_USER_INSTANCES);
    },
    setCurrentSetting(setting) {
      this.currentSetting = setting;
      setTimeout(() => this.$root.$emit("bv::hide::popover"), 50);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .help-text-settings {
    background: #fff;
    padding: 8px 16px;
    box-shadow: 0px 4px 12px rgba(52, 58, 64, 0.15);
    border-radius: 6px;
  }

  .status-options {
    background: #fff;
    width: 500px;
    padding: 8px;
    box-shadow: 0px 4px 12px rgba(52, 58, 64, 0.15);
    border-radius: 4px;

    .option {
      display: flex;
      align-items: center;
      width: 100%;
      text-align: start;
      background: #f8f9fa;
      border-radius: 4px;
      margin-bottom: 8px;
      padding: 10px 16px;
      cursor: pointer;
      transition: all 0.07s ease-out;
      user-select: none;

      .checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 12px;
        background: #ffffff;
        border: 1px solid #ced4da;
        border-radius: 100px;
        margin-right: 8px;

        .inner {
          width: 8px;
          height: 8px;
          background: $green;
          border-radius: 100%;
        }
      }

      &:hover {
        background: #e8eaeb;
      }

      &.active {
        background: #dee2e6;

        .checkbox {
          border: 1px solid $green;
        }

        &:hover {
          background: #d2d6d8;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.channel-settings-modal-wrapper {
  .channel-settings-modal {
    height: calc(100vh - 64px);
    width: 640px;
    background: #fff;
    padding: 32px 48px;

    h2 {
      color: $green;
    }

    .messages {
      position: relative;

      .current-status {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: $green;
        text-decoration-line: underline;
        text-decoration-style: dotted;
        cursor: pointer;
      }
    }

    .crm-settings {
      .with-help {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        h5 {
          margin: 0;
        }

        .help {
          width: 20px;
          height: 20px;
          background: #f8f9fa;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 12px;
          cursor: pointer;

          p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: $green;
          }
        }
      }
    }

    .subscription {
      .date {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #343a40;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    background: #f8f9fa;
    padding: 16px;

    button {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #868e96;
      background: transparent;
      padding: 12px 16px;

      &.succeed {
        background: #28a745;
        color: #fff;
      }
    }
  }

  .spacer {
    flex-grow: 1;
  }
}
</style>
