<template lang="pug">
.input-wrapper
  .name {{ name }}
  .input
    p {{ value }}
    .icon-wrapper(v-if="copy" @click="copyText")
      EIcon.icon(name="copy" width="13px" height="16px" color="#868E96")
</template>

<script>
export default {
  name: "Common.Input",
  props: {
    name: {
      type: String,
    },
    value: {
      type: String || Number,
    },
    copy: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "Введите текст...",
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    copyText() {
      window.navigator.clipboard.writeText(this.copy);
      window.info(
        "Текст скопирован в буфер обмена, нажмите Ctrl+V, чтобы вставить его",
        "Текст успешно скопирован"
      );
    },
  },
};
</script>

<style lang="scss">
.input-wrapper {
  margin: 8px 0 24px;

  .name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #343a40;
    margin-bottom: 4px;
  }

  .input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 160px;
    max-width: 374px;
    width: fit-content;
    height: 40px;
    background: #f8f9fa;
    border-radius: 4px;
    padding: 12px 8px;

    .icon {
      cursor: pointer;
      color: #868e96;
      margin: 0 8px;
    }

    p {
      color: $green;
      font-size: 14px;
      line-height: 16px;

      &::placeholder {
        color: #868e96;
      }
    }
  }
}
</style>
